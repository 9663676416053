<template>
	<div class="partial partial--artist">
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<content-band>
			<div slot="img-top" class="content-band__image content-band__image-short" :style="bioImage"></div>
			<h3 class="content-band__artist-profile">Artist Profile</h3>
			<h2 class="content-band__title text-white">Mike Parsons</h2>
			<p>Mike is a professional tattoo artist & illustrator of over 25 years. The recipient of a multitude of awards, features & accolades, Mike has carved out a sizable niche in his industry. As Prick magazine puts it: <em>"Just like every kid who picks up a guitar needs to know Jimi Hendrix, every tattoo artist needs to know Mike Parsons."</em></p>
			
		</content-band>

		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>

		<image-grid :images="images"></image-grid>
	</div>
</template>

<script>
// Components
import ContentBand from '@/components/ContentBand'
import ImageGrid from '@/components/ImageGrid'
import ButtonGallery from '@/components/ButtonGallery'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artist',
	components: {
		ContentBand,
		ImageGrid,
		ButtonGallery,
		Artists,
	},
	data: () => ({
		bioImage: {
			backgroundImage: 'url(' + require('@/assets/images/artist-mikeparsons.jpg') + ')'
		},
		images: [
			require('@/assets/images/mike/38.jpg'),
			require('@/assets/images/mike/20.jpg'),
			require('@/assets/images/mike/2.jpg'),
			require('@/assets/images/mike/og_2.jpg'),
			//require('@/assets/images/mike/3.jpg'),
			require('@/assets/images/mike/4.jpg'),
			require('@/assets/images/mike/5.jpg'),
			require('@/assets/images/mike/6.jpg'),
			require('@/assets/images/mike/7.jpg'),
			require('@/assets/images/mike/og_1.jpg'),
			//require('@/assets/images/mike/8.jpg'),
			require('@/assets/images/mike/9.jpg'),
			//require('@/assets/images/mike/10.jpg'),
			//require('@/assets/images/mike/11.jpg'),
			require('@/assets/images/mike/12.jpg'),
			//require('@/assets/images/mike/14.jpg'),
			require('@/assets/images/mike/15.jpg'),
			require('@/assets/images/mike/17.jpg'),
			//require('@/assets/images/mike/18.jpg'),
			require('@/assets/images/mike/19.jpg'),
			require('@/assets/images/mike/1.jpg'),
			require('@/assets/images/mike/21.jpg'),
			//require('@/assets/images/mike/22.jpg'),
			require('@/assets/images/mike/23.jpg'),
			require('@/assets/images/mike/24.jpg'),
			require('@/assets/images/mike/25.jpg'),
			require('@/assets/images/mike/26.jpg'),
			//require('@/assets/images/mike/27.jpg'),
			//require('@/assets/images/mike/28.jpg'),
			require('@/assets/images/mike/29.jpg'),
			require('@/assets/images/mike/30.jpg'),
			require('@/assets/images/mike/31.jpg'),
			require('@/assets/images/mike/32.jpg'),
			require('@/assets/images/mike/33.jpg'),
			require('@/assets/images/mike/34.jpg'),
			require('@/assets/images/mike/35.jpg'),
			require('@/assets/images/mike/36.jpg'),
			//require('@/assets/images/mike/37.jpg'),
			require('@/assets/images/mike/39.jpg'),
			require('@/assets/images/mike/40.jpg'),
			require('@/assets/images/mike/41.jpg'),
			require('@/assets/images/mike/42.jpg'),
			//require('@/assets/images/mike/og_2.jpg'),
			require('@/assets/images/mike/mike-43.jpg'),
			require('@/assets/images/mike/mike-44.jpg'),
			require('@/assets/images/mike/mike-45.jpg'),
			require('@/assets/images/mike/mike-46.jpg'),
			require('@/assets/images/mike/mike-47.jpg'),
			require('@/assets/images/mike/mike-48.jpg'),
			require('@/assets/images/mike/mike-49.jpg'),
			require('@/assets/images/mike/mike-50.jpg'),
			require('@/assets/images/mike/mike-51.jpg'),
			require('@/assets/images/mike/mike-52.jpg'),
			require('@/assets/images/mike/mike-53.jpg'),
			require('@/assets/images/mike/54.jpg'),
			require('@/assets/images/mike/55.jpg'),
			require('@/assets/images/mike/56.jpg'),
			require('@/assets/images/mike/57.jpg'),
			require('@/assets/images/mike/58.jpg'),
			require('@/assets/images/mike/59.jpg'),
			require('@/assets/images/mike/60.jpg'),
			require('@/assets/images/mike/61.jpg'),
			require('@/assets/images/mike/62.jpg')
		],
		show: false
	}),
	metaInfo() {
		return {
			title: 'Mike Parsons',
			titleTemplate: '%s | Mike Parsons Ink',
			script: [
				{
					type: 'application/ld+json',
					innerHTML: `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Mike Parsons",
    "item": "https://mikeparsonsink.com/mike-parsons"
  }]
}`,
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>

	.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	
</style>
